import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAPI, postDataAPI, putDataAPI } from "../../utils/FetchData";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";
import { scheduleTaskStatusArray } from "../../common/common";


export const get_schedule_task_thunk: any = createAsyncThunk('user/employee/get_employee_schedule_task_list', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        let response = await postDataAPI('user/employee/get_employee_schedule_task_list', { status: tempData.status, assignedDateFilter: tempData.assignedDateFilter }, tempData.token);
        console.log('response?.data', response?.data)
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})


export const save_schedule_task_thunk: any = createAsyncThunk('user/employee/save_schedule_task', async (tempData: any, thunkAPI) => {
    try {
        let response = await putDataAPI('user/employee/save_schedule_task', tempData.data, tempData.token);
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        setTimeout(() => window.location.reload(), 2000);
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})
export const complete_schedule_task_thunk: any = createAsyncThunk('user/employee/complete_schedule_task', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        let response = await putDataAPI('user/employee/complete_schedule_task', tempData.data, tempData.token);
        thunkAPI.dispatch(get_schedule_task_thunk({ status: scheduleTaskStatusArray[0].status, token: tempData.token, assignedDateFilter: tempData.assignedDateFilter }));
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        setTimeout(() => window.location.reload(), 2000);
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

