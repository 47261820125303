import { createAsyncThunk } from "@reduxjs/toolkit";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";
import { getDataAPI, postDataAPI } from "../../utils/FetchData";

export const create_email_thunk: any = createAsyncThunk('user/email/create_email', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const body = { ...tempData };
        delete body.token;
        let response = await postDataAPI('user/email/create_email', body, tempData.token);
        thunkAPI.dispatch(get_emails_thunk({ token: tempData.token }))
        thunkAPI.dispatch(setLoader(false))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'Email created successfully'));
        return response?.data?.data?.response
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const get_emails_thunk: any = createAsyncThunk('user/email/get_email_list', async (tempData: any, thunkAPI) => {
    try {
        const response = await getDataAPI(`user/email/get_email_list`, tempData?.token);
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})


