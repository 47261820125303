import React from 'react'
import Header from '../components/header/Header'
import Questions from '../components/home/Questions'

export default function Home() {
    return (
        // <div className='flex  items-center flex-col h-[100vh] gap-10 m-[10px]'>
        //     <NewDocumentForm />
        //     <DocList />
        // </div>
        <>
            <Header />
            <Questions />
        </>
    )
}
