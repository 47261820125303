import React from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { google_login_thunk } from '../redux/auth/authThunk';
import { setErrorAlert } from '../redux/alert/alertSlice';
import axios from 'axios';
let REACT_APP_GOOGLE_CLIENT_ID: any = process.env.REACT_APP_GOOGLEC_CLIENT_ID

export default function Login() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const getTimeZone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };

    const getIp = async () => {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        console.log('data', data)
        return data
    }

    const handleGoogleUserData = async (response: any) => {
        let userData: any = jwtDecode(response.credential)
        console.log('userData', userData)


        const timeZone = getTimeZone()
        const ip = await getIp()
        let data = {
            firstName: userData.given_name,
            lastName: userData.family_name,
            email: userData.email,
            image: userData.picture,
            timeZone: timeZone,
            ip: ip
        }
        dispatch(google_login_thunk({ data })).then((res: any) => {
            navigate('/')
        })
    }

    const handleError: any = (response: any) => {
        setErrorAlert("Google Login Failed")
    }
    return (
        <div className="App">
            <header className="App-header">
                <div className=" text-white font-semibold py-2 px-4 rounded">

                    <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
                        <GoogleLogin
                            onSuccess={handleGoogleUserData}
                            onError={handleError}
                        />
                    </GoogleOAuthProvider>
                </div>
            </header >
        </div >
    )
}
