import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { logout_thunk } from '../../redux/auth/authThunk';
import { useDispatch } from 'react-redux';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const currentActiveLink = window.location.pathname;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-lg font-bold">User Website</div>

        <div className="flex items-center">
          {/* Hamburger Button */}
          <button onClick={toggleMenu} className="md:hidden text-white focus:outline-none">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
            </svg>
          </button>

          {/* Navigation Links and Logout Button - Hidden on small screens, shown on medium and up */}
          <nav className={`${isOpen ? 'flex' : 'hidden'} flex-col absolute top-[60px] z-10 p-[10px] bg-gray-800 md:top-0 md:p-0 w-full left-0  md:flex md:flex-row md:relative md:justify-end md:items-center md:w-auto md:gap-4`}>
            <Link to={"/"} className="p-2 " style={{ color: currentActiveLink === "/" ? "rgb(59 130 246)" : "white" }}>Schedule Task</Link>
            <Link to={"/onetimetask"} className="p-2 " style={{ color: currentActiveLink === "/onetimetask" ? "rgb(59 130 246)" : "white" }}>OneTime Task</Link>
            {/* <Link to={"/schedule-task"} className="p-2 " style={{ color: currentActiveLink === "/schedule-task" ? "rgb(59 130 246)" : "white" }}>Schedule Task</Link> */}
            {/* <Link to={"/history"} className="p-2 hover:text-blue-500" style={{ color: currentActiveLink === "/history" ? "rgb(59 130 246)" : "white" }}>Tasks</Link> */}
            <button onClick={() => dispatch(logout_thunk())} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2 md:mt-0">
              Logout
            </button>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
