import { createSlice } from "@reduxjs/toolkit";
import { complete_onetime_task_thunk, get_onetime_task_thunk, save_onetime_task_thunk } from "./onetimetaskThunk";

const initialState = {
    data: null,
    error: null,
    loading: false,
}

const getOneTimeTasks = (builder: any) => {
    builder.addCase(get_onetime_task_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(get_onetime_task_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.data = action.payload.data.response
    }).addCase(get_onetime_task_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}

const saveAnswerCases = (builder: any) => {
    builder.addCase(save_onetime_task_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(save_onetime_task_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(save_onetime_task_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}
const confirmAnswerCases = (builder: any) => {
    builder.addCase(complete_onetime_task_thunk.pending, (state: any) => {
        state.loading = true;
        state.error = null;
    }).addCase(complete_onetime_task_thunk.fulfilled, (state: any, action: any) => {
        state.loading = false;
    }).addCase(complete_onetime_task_thunk.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload || 'Redux Toolkit Error'
    })
}


const alertSlice = createSlice({
    name: "onetimeTask",
    initialState,
    reducers: {
        clearOneTimeDocs: (state) => {
            state.data = null;
            state.error = null;
            state.loading = false
        },
    },
    extraReducers: (builder) => {
        getOneTimeTasks(builder)
        saveAnswerCases(builder)
        confirmAnswerCases(builder)
    }
})

export const { clearOneTimeDocs } = alertSlice.actions;

export default alertSlice.reducer;
