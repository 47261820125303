import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import OneTimeTask from '../pages/OneTimeTask'



function PrivateRoutes() {
    return (

        <Routes >
            <Route path='' element={<Home />} />
            <Route path='/onetimetask' element={<OneTimeTask />} />
            {/* <Route path='/plans' element={<Plans />} /> */}
            {/* <Route path='/paypalCheckout' element={<Paypal />} /> */}
        </Routes>
    )
}

export default PrivateRoutes
