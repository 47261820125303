import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import alertReducer from './alert/alertSlice';
import docReducer from './doc/docSlice';
import folderReducer from './folder/folderSlice';
import authReducer from './auth/authSlice';
import emailReducer from './email/emailSlice';
import scheduleTaskReducer from './scheduletask/scheduleTaskSlice';
import onetimetaskReducer from './onetimetask/onetimetaskSlice';

const store = configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        doc: docReducer,
        folder: folderReducer,
        email: emailReducer,
        scheduleTask: scheduleTaskReducer,
        onetimeTask: onetimetaskReducer
    },
});

export const useAppDispatch = () => useDispatch();
export default store;