export const statusArray: any = [
    {
        id: 1,
        status: "Pending"
    },
    {
        id: 2,
        status: "Completed"
    }
]

export const scheduleTaskStatusArray: any = [
    {
        id: 1,
        status: "Assigned"
    },
    {
        id: 2,
        status: "Completed"
    },
    {
        id: 3,
        status: "Expired"
    },
]

export const dateFilterArray: any = [
    {
        id: 1,
        status: "thisWeek"
    },
    {
        id: 2,
        status: "thisMonth"
    },
    {
        id: 3,
        status: "thisYear"
    },
    {
        id: 4,
        status: "all"
    }
]