import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { complete_schedule_task_thunk, get_schedule_task_thunk, save_schedule_task_thunk } from '../../redux/scheduletask/scheduleTaskThunk';
import { dateFilterArray, scheduleTaskStatusArray } from '../../common/common';
import { clearAlerts, setErrorAlert } from '../../redux/alert/alertSlice';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import moment from 'moment-timezone';

interface createdByObj {
    _id: string;
    username: string;
    email: string;
}
interface Task {
    _id: string;
    question: string;
    answerType: number;
    answer?: string;
    status: string;
    createdBy: createdByObj;
}

const Questions: React.FC = () => {
    const token = localStorage.getItem('userS3Token');
    const tasks = useSelector((state: any) => state.scheduleTask?.data);
    const [answers, setAnswers] = useState<{ [key: string]: { answer: string, confirm: string } }>({});
    const [status, setStatus]: any = useState(null);
    const [assignedDateFilter, setAssignedDateFilter]: any = useState(null);

    const dispatch = useDispatch();



    useEffect(() => {
        if (tasks?.length > 0) {
            const initialAnswers = tasks.reduce((acc: any, task: any) => {
                acc[task._id] = { answer: task.answer || '', confirm: '' };
                return acc;
            }, {} as { [key: string]: { answer: string, confirm: string } });

            setAnswers(initialAnswers);
        }
    }, [tasks]);

    useEffect(() => {
        let statusInp = status ? status.status : scheduleTaskStatusArray[0].status
        let assignedDateFilterInp = assignedDateFilter ? assignedDateFilter.status : dateFilterArray[0].status
        dispatch(clearAlerts())
        dispatch(get_schedule_task_thunk({ status: statusInp, token, assignedDateFilter: assignedDateFilterInp }));
    }, [dispatch, , status, assignedDateFilter]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>, taskId: string) => {
        event.preventDefault();
        let data = {
            _id: taskId,
            answer: answers[taskId]?.answer,
        }

        if (!answers[taskId]?.answer) {
            dispatch(setErrorAlert('Please provide an answer'))
            return
        }

        dispatch(clearAlerts())
        let assignedDateFilterInp = assignedDateFilter ? assignedDateFilter.status : dateFilterArray[0].status
        dispatch(complete_schedule_task_thunk({ data: data, token: token, assignedDateFilter: assignedDateFilterInp }))
    };

    const handleSave = (event: React.FormEvent<HTMLFormElement>, taskId: string) => {
        event.preventDefault();

        let data = {
            _id: taskId,
            answer: answers[taskId]?.answer,
        }

        if (!answers[taskId]?.answer) {
            dispatch(setErrorAlert('Please provide an answer'))
            return
        }

        dispatch(clearAlerts())
        dispatch(save_schedule_task_thunk({ data: data, token: token }))
    };

    const handleAnswerChange = (taskId: string, answer: string) => {
        setAnswers({
            ...answers,
            [taskId]: {
                ...answers[taskId],
                answer: answer,
            }
        });
    };

    const handleConfirmChange = (taskId: string, confirm: string) => {
        setAnswers({
            ...answers,
            [taskId]: {
                ...answers[taskId],
                confirm: confirm,
            }
        });
    };

    const convertUtcTimeToCountryTime = (utcTime: string, timezone: string): string => {
        console.log('utcTime', utcTime)
        console.log('timeZone', timezone)
        if (!utcTime || !timezone) {
            throw new Error("Both utcTime and timezone must be provided");
        }

        const countryTime = moment.utc(utcTime).tz(timezone);
        return countryTime.format('h:mm A'); // Format to get output like '8:00 AM'
    };

    return (
        <div className="w-full md:w-2/3 mx-auto p-5 bg-white rounded-lg shadow">
            <div className="flex items-center justify-between">
                <div className="w-2/3">
                    <h2 className="section-heading font-bold" id="focus-id">
                        Questions and Answers
                    </h2>
                </div>

                <div className="relative flex justify-end items-center w-1/3">
                    <div className="flex w-full space-x-4">
                        <FormControl fullWidth variant="outlined" className='flex-grow'>
                            <InputLabel>Select A Filter</InputLabel>
                            <Select
                                value={assignedDateFilter ? assignedDateFilter.id : dateFilterArray[0].id}
                                onChange={(e) => {
                                    const id = e.target.value;
                                    const dateFilter = dateFilterArray.find((p: any) => p.id === id);
                                    setAssignedDateFilter(dateFilter || null);
                                }}
                                label="Select A Status"
                            >
                                {dateFilterArray?.length > 0 && dateFilterArray.map((p: any) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth variant="outlined" className='flex-grow'>
                            <InputLabel>Select A Filter</InputLabel>
                            <Select
                                value={status ? status.id : scheduleTaskStatusArray[0].id}
                                onChange={(e) => {
                                    const id = e.target.value;
                                    const selectedStatus = scheduleTaskStatusArray.find((p: any) => p.id === id);
                                    setStatus(selectedStatus || null);
                                }}
                                label="Select A Person"
                            >
                                {scheduleTaskStatusArray?.length > 0 && scheduleTaskStatusArray.map((p: any) => (
                                    <MenuItem key={p.id} value={p.id}>
                                        {p.status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

            </div>

            <div className="mt-8 space-y-8">
                {tasks?.length > 0 ? tasks.map((task: any) => (
                    <div key={task._id} className="bg-gray-200 p-4 rounded-lg">
                        <div className="flex items-center space-x-4">
                            <span className="inline-flex justify-center items-center w-6 h-6 rounded-full bg-green-500 text-white font-medium text-sm">
                                Q
                            </span>
                            <div >
                                <p className="font-bold">
                                    {task?.title || task?.question}
                                </p>

                            </div>

                        </div>
                        <div className='flex justify-between text-gray-600 w-full mt-[10px]'>
                            <div className='flex gap-x-2'>
                                <p>
                                    {convertUtcTimeToCountryTime(task?.countryTime, task?.createdFor?.timeZone)} - {task.day}
                                </p>
                                <p>
                                    {task?.assignedDate?.split("T")[0]}
                                </p>
                                {(!status || status?.status === "Assigned") && <p>
                                    {task.deadlineHour && ` Expire in ${task.deadlineHour} hour`}
                                </p>}
                                {status?.status === "Expired" && <p>
                                    {task.deadlineHour && `Deadline was ${task.deadlineHour} hour`}
                                </p>}
                            </div>
                            <p>
                                Assigned By: {task?.createdBy?.username}
                            </p>

                        </div>
                        <div className='mt-[10px]'>
                            <p className="text-sm text-gray-600 ">
                                description : {task?.question}
                            </p>
                        </div>
                        {task?.status === "Assigned" ?
                            <>
                                {task?.answerType === 1 ? (
                                    <form onSubmit={(e) => handleSubmit(e, task._id)} className="flex flex-col mt-3">
                                        <div className="flex items-center space-x-2">
                                            <span className="inline-flex justify-center items-center w-6 h-6 rounded-full bg-blue-300 text-gray-800 font-medium text-sm">
                                                A
                                            </span>
                                            <iframe
                                                id="myIframe"
                                                className="ml-4 md:ml-6 w-full h-[500px]"
                                                src={`https://docs.google.com/document/d/${task?.answer}`}
                                                title={`Document ${task?.answer}`}
                                            ></iframe>
                                        </div>
                                        <div className="mt-3 self-end flex space-x-2">
                                            <input
                                                type="text"
                                                placeholder="Type confirm here to submit"
                                                className="px-4 py-2 rounded-lg border border-gray-300 outline-none"
                                                value={answers[task._id]?.confirm || ''}
                                                onChange={(e) => handleConfirmChange(task._id, e.target.value)}
                                            />
                                            <button
                                                className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:bg-gray-300 disabled:text-gray-600"
                                                disabled={answers[task._id]?.confirm !== "confirm"}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                ) : (
                                    <form onSubmit={(e) => handleSubmit(e, task._id)} className="flex flex-col mt-3">
                                        <div className="flex items-center space-x-2">
                                            <span className="inline-flex justify-center items-center w-6 h-6 rounded-full bg-blue-300 text-gray-800 font-medium text-sm">
                                                A
                                            </span>
                                            <textarea
                                                className="ml-4 md:ml-6 p-2 w-full rounded-lg border border-gray-300 outline-none"
                                                placeholder="Write your answer..."
                                                maxLength={140}
                                                value={answers[task._id]?.answer || ''}
                                                onChange={(e) => handleAnswerChange(task._id, e.target.value)}
                                            />
                                        </div>
                                        <div className="mt-3 self-end flex space-x-2">
                                            <button
                                                className="px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600"
                                                onClick={(e: any) => handleSave(e, task._id)}
                                            >
                                                Save
                                            </button>
                                            <input
                                                type="text"
                                                placeholder="Type confirm here to submit"
                                                className="px-4 py-2 rounded-lg border border-gray-300 outline-none"
                                                value={answers[task._id]?.confirm || ''}
                                                onChange={(e) => handleConfirmChange(task._id, e.target.value)}
                                            />
                                            <button
                                                className="px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 disabled:bg-gray-300 disabled:text-gray-600"
                                                disabled={answers[task._id]?.confirm !== "confirm"}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                )}
                            </>
                            :
                            <>
                                {task?.answerType === 1 ? (
                                    <div className="flex flex-col mt-3">
                                        <div className="flex items-center space-x-2">
                                            <span className="inline-flex justify-center items-center w-6 h-6 rounded-full bg-blue-200 text-gray-800 font-medium text-sm">
                                                A
                                            </span>
                                            <iframe
                                                id="myIframe"
                                                className="ml-4 md:ml-6 w-full h-[500px]"
                                                src={`https://docs.google.com/document/d/${task?.answer}`}
                                                title={`Document ${task?.answer}`}
                                            ></iframe>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex flex-col mt-3">
                                        <div className="flex items-center space-x-2">
                                            <span className="inline-flex justify-center items-center w-6 h-6 rounded-full bg-blue-200 text-gray-800 font-medium text-sm">
                                                A
                                            </span>
                                            <div className="ml-4 md:ml-6 p-2 w-full rounded-lg border border-gray-300 outline-none bg-white">
                                                {answers[task._id]?.answer || ''}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        }
                    </div>

                )) : <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    {tasks === null ? (
                        <></>
                        // <CircularProgress size={35} color="secondary" thickness={5} />
                    ) : (
                        <Typography variant="body2">No tasks found</Typography>
                    )}
                </Box>

                }
            </div>
        </div>
    );
};

export default Questions;
