import React from 'react'
import Header from '../components/header/Header'
import Questions from '../components/home/Questions'
import OneTime from '../components/onetime/OneTime'

export default function OneTimeTask() {
    return (
        <>
            <Header />
            <OneTime />
        </>
    )
}
