import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAPI, postDataAPI, putDataAPI } from "../../utils/FetchData";
import { setErrorAlert, setLoader, setSuccessAlert } from "../alert/alertSlice";

export const google_login_thunk: any = createAsyncThunk('user/auth/google_login_user', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const google_login_thunk_res = await postDataAPI('user/auth/google_login_user', tempData?.data, {});
        thunkAPI.dispatch(setSuccessAlert(google_login_thunk_res?.data?.message))

        localStorage.setItem('userS3Token', JSON.stringify(google_login_thunk_res?.data?.data?.token));
        localStorage.setItem('userS3User', JSON.stringify(google_login_thunk_res?.data?.data?.response));

        return google_login_thunk_res.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})


export const logout_thunk: any = createAsyncThunk('auth/logout', async (tempData, thunkAPI) => {
    try {
        localStorage.removeItem('userS3Token')
        localStorage.removeItem('userS3User')
        window.location.href = "/"
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const get_prompt_thunk: any = createAsyncThunk('user/userActivity/getPrompt', async (tempData: any, thunkAPI) => {
    try {
        const response = await getDataAPI(`user/userActivity/getPrompt`, tempData?.token);
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})

export const update_prompt_thunk: any = createAsyncThunk('user/userActivity/updatePrompt', async (tempData: any, thunkAPI) => {
    try {
        thunkAPI.dispatch(setLoader(true))
        const response = await putDataAPI(`user/userActivity/updatePrompt`, tempData.data, tempData?.token);
        await thunkAPI.dispatch(get_prompt_thunk({ token: tempData.token }))
        thunkAPI.dispatch(setSuccessAlert(response?.data?.message || 'pPrompt saved successfully'));
        thunkAPI.dispatch(setLoader(false))
        return response?.data
    } catch (error: any) {
        console.log('error', error)
        thunkAPI.dispatch(setErrorAlert(error?.response?.data?.message || 'An error occurred'));
        return thunkAPI.rejectWithValue(error?.response?.data?.message || 'An error occurred');
    }
})