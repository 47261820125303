import './App.css';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode'
import PrivateRoutes from './routing/PrivateRoutes';
import Alert from './components/Alert/Alert';
import AuthRoutes from './routing/AuthRoutes';
import { logout_thunk } from './redux/auth/authThunk';
// import Alert from './components/Loader/Alert';
function App() {

  // const queryParams = new URLSearchParams(window.location.search);
  // let token: any = queryParams.get('token');

  // if (token) {
  //   localStorage.setItem('userS3Token', token);
  // }

  let tokenState = localStorage.getItem('userS3Token');
  let bool = false
  if (tokenState) {
    bool = true
  }
  const [currentUser, setCurrentUser] = useState(bool);

  const auth = useSelector((state: any) => state.auth?.isLogin);
  const dispatch = useDispatch()
  useEffect(() => {
    const token = localStorage.getItem('userS3Token');
    const isLoggedIn: any = token;
    setCurrentUser(isLoggedIn);

    const checkTokenExpiration = () => {
      if (token) {
        const decodedToken: any = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp <= currentTime) {
          dispatch(logout_thunk());
        }
      }
    };

    checkTokenExpiration();
  }, [dispatch, auth]);



  return (

    <>
      <Alert />
      <Router>
        <Routes>
          {/* <Route path='/*' element={<PrivateRoutes />} /> */}

          {
            !currentUser ?
              <>
                <Route path='/*' element={<AuthRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
              :
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route path='*' element={<Navigate to='/' />} />
              </>
          }
        </Routes>
      </Router>
    </>
  );
}

export default App;
